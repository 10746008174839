.editIcon {
  font-size: x-large;
}

mat-icon {
  font-size: 19px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.centerActionsContainer {
  display: flex;
  justify-content: start;
}
