.authenticationLayout {
  display: flex;
  height: 100vh;
  background-image: url("../../img/loginBG.jpg");
  background-size: cover;
  background-position-y: center;
  .formContainer {
    flex: calc(1 / 3);
    margin-left: auto;
    padding: 40px 48px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.89);
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12.1%;
      figure {
        height: 42px;
        width: 160px;
        img {
          height: 100%;
        }
      }
    }
  }
}
.footerLink {
  margin-top: auto;
  align-items: center;
  text-align: center;
}
