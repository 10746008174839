* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // outline: 1px solid red;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // background: var(--primary-color);
    background: lightgray;
  }
}

html {
  overflow: hidden;
}

.p-24 {
  padding: 24px;
}

.cpt-8 {
  padding-top: 8px;
}
.cpr-8 {
  padding-right: 8px;
}
.cpb-8 {
  padding-bottom: 8px;
}
.cpl-8 {
  padding-left: 8px;
}

.cpt-16 {
  padding-top: 16px;
}
.cpr-16 {
  padding-right: 16px;
}
.cpb-16 {
  padding-bottom: 16px;
}
.cpl-16 {
  padding-left: 16px;
}

.cpt-24 {
  padding-top: 24px;
}
.cpr-24 {
  padding-right: 24px;
}
.cpb-24 {
  padding-bottom: 24px;
}
.cpl-24 {
  padding-left: 24px;
}

.cpt-32 {
  padding-top: 32px;
}
.cpr-32 {
  padding-right: 32px;
}
.cpb-32 {
  padding-bottom: 32px;
}
.cpl-32 {
  padding-left: 32px;
}

.cpt-40 {
  padding-top: 40px;
}
.cpr-40 {
  padding-right: 40px;
}
.cpb-40 {
  padding-bottom: 40px;
}
.cpl-40 {
  padding-left: 40px;
}

.cpt-48 {
  padding-top: 48px;
}
.cpr-48 {
  padding-right: 48px;
}
.cpb-48 {
  padding-bottom: 48px;
}
.cpl-48 {
  padding-left: 48px;
}

.cpt-56 {
  padding-top: 56px;
}
.cpr-56 {
  padding-right: 56px;
}
.cpb-56 {
  padding-bottom: 56px;
}
.cpl-56 {
  padding-left: 56px;
}

.cpt-64 {
  padding-top: 64px;
}
.cpr-64 {
  padding-right: 64px;
}
.cpb-64 {
  padding-bottom: 64px;
}
.cpl-64 {
  padding-left: 64px;
}

/* Margin Classes */
.cmt-8 {
  margin-top: 8px;
}
.cmr-8 {
  margin-right: 8px;
}
.cmb-8 {
  margin-bottom: 8px;
}
.cml-8 {
  margin-left: 8px;
}

.cmt-16 {
  margin-top: 16px;
}
.cmr-16 {
  margin-right: 16px;
}
.cmb-16 {
  margin-bottom: 16px;
}
.cml-16 {
  margin-left: 16px;
}

.cmt-24 {
  margin-top: 24px;
}
.cmr-24 {
  margin-right: 24px;
}
.cmb-24 {
  margin-bottom: 24px;
}
.cml-24 {
  margin-left: 24px;
}

.cmt-32 {
  margin-top: 32px;
}
.cmr-32 {
  margin-right: 32px;
}
.cmb-32 {
  margin-bottom: 32px;
}
.cml-32 {
  margin-left: 32px;
}

.cmt-40 {
  margin-top: 40px;
}
.cmr-40 {
  margin-right: 40px;
}
.cmb-40 {
  margin-bottom: 40px;
}
.cml-40 {
  margin-left: 40px;
}

.cmt-48 {
  margin-top: 48px;
}
.cmr-48 {
  margin-right: 48px;
}
.cmb-48 {
  margin-bottom: 48px;
}
.cml-48 {
  margin-left: 48px;
}

.cmt-56 {
  margin-top: 56px;
}
.cmr-56 {
  margin-right: 56px;
}
.cmb-56 {
  margin-bottom: 56px;
}
.cml-56 {
  margin-left: 56px;
}

.cmt-64 {
  margin-top: 64px;
}
.cmr-64 {
  margin-right: 64px;
}
.cmb-64 {
  margin-bottom: 64px;
}
.cml-64 {
  margin-left: 64px;
}

.file-placeholder {
  padding-top: 6px;
  padding-left: 15%;
}
