.detailsForm {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
}
.gridForm {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  gap: 16px;
  flex-wrap: wrap;
  .inputContainer {
    min-width: 305px;
    ng-select {
      width: 100%;
    }
  }
}
