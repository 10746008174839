.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  label {
    margin-bottom: 8px;
  }
  ng-select {
    width: 100%;
  }
}
.display-none {
  display: none !important;
}

.listOfTitle {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  h3 {
    color: var(--breadcrumb-link-color);
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  & > span {
    width: 38px;
    height: 23px;
    border-radius: 100px;
    background: var(--list-label-number-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.requiredStar {
  color: var(--danger-color);
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.warningContainer {
  border-radius: 4px;
  border: 1px solid var(--warning-bg-color);
  background: var(--warning-bg-color);
  padding: 16px;
  margin: 1rem 0;
}

.errorContainer {
  border-radius: 4px;
  border: 1px solid var(--error-border);
  background: var(--snackBar-error-bg);
  padding: 16px;
  margin: 1rem 0;
}

.stepperSubheader {
  display: flex;
  align-items: center;
  gap: 16px;
  button {
    min-width: 190px;
    font-size: 0.813rem;
  }
  .notSelected {
    background-color: #fff;
    color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}
