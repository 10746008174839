mat-snack-bar-container {
  span.mdc-button__label {
    scale: 2;
    color: var(--label-color);
  }
  .mdc-snackbar__surface {
    div[matsnackbarlabel] {
      padding-left: 48px;
      position: relative;
      font-size: 0.813rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 4px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  &.error-message {
    .mdc-snackbar__surface {
      border: 1px solid var(--error-border);
      background: var(--snackBar-error-bg);
      div[matsnackbarlabel] {
        color: var(--danger-color) !important;
        &::after {
          background-image: url("../../icons/x-circle.svg");
        }
      }
    }
  }
  &.warning-message {
    .mdc-snackbar__surface {
      border: 1px solid var(--warning-border);
      background: var(--snackBar-warning-bg);
      div[matsnackbarlabel] {
        color: var(--warning-font-color) !important;
        &::after {
          background-image: url("../../icons/alert-triangle.svg");
        }
      }
    }
  }
  &.success-message {
    .mdc-snackbar__surface {
      border: 1px solid var(--list-label-number-color);
      background: var(--snackBar-success-bg);
      div[matsnackbarlabel] {
        color: var(--primary-color) !important;
        &::after {
          background-image: url("../../icons/check-circle-without-bg.svg");
        }
      }
    }
  }
}
