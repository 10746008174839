.paginationContainer {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 16px;
  select {
    margin: 0 8px;
    border-radius: 3px;
    border: 0.5px solid var(--input-border-color);
    background: #fff;
  }
  .pageControlContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
    pagination-controls {
      pagination-template {
        nav {
          ul {
            margin: 0;
            display: flex;
            gap: 16px;
            li {
              a {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &.current {
                border-radius: 50%;
                background-color: var(--primary-color);
                color: #fff;
                padding: 0;
                display: flex;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
              }
              &.pagination-next {
                a {
                  &::after {
                    margin-left: 0;
                  }
                }
              }
              &.pagination-previous {
                a {
                  &::before {
                    margin-right: 0;
                  }
                }
              }
              &.ng-star-inserted.current {
                span {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
