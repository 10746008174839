button {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 5px 16px;
  gap: 10px;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 2.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  &:disabled {
    opacity: 0.6;
  }
  &.primary {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #fff;
    &:not(:disabled):hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      background-color: #fff;
    }
  }
  &.outlinedPrimary {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: #fff;
    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
  &.green {
    background-color: var(--save-color);
    color: #fff;
    border: 1px solid var(--save-color);
    &:hover {
      background-color: #fff;
      color: var(--save-color);
      border: 1px solid var(--save-color);
    }
  }
  &.white {
    color: var(--breadcrumb-link-color);
    background-color: #fff;
    border: 1px solid #fff;
  }

  &.red {
    color: #fff;
    background-color: var(--danger-color);
    border: 1px solid var(--danger-color);
    &:hover {
      color: var(--danger-color);
      background-color: #fff;
      border: 1px solid var(--danger-color);
    }
  }

  &.greyOutline {
    color: var(--breadcrumb-link-color);
    background-color: #fff;
    border: 1px solid var(--breadcrumb-link-color);
    &:hover {
      color: #fff;
      background-color: var(--breadcrumb-link-color);
      border: 1px solid var(--breadcrumb-link-color);
    }
  }

  &.iconOnly {
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: #fff;
    i {
      height: 100%;
      width: 100%;
    }
  }

  &.plus {
    padding: 0;
    height: 28px;
    width: 28px;
    min-height: 28px;
    min-width: 28px;
    border-radius: 4px;
    background-color: var(--primary-color);
    background-image: url("../../../assets/icons/plusIcon.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  &.minus {
    @extend .plus;
    background-image: url("../../../assets/icons/minusIcon.svg");
  }
}
