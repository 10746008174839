.tableAndPaginationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .tableWithActionsContainer {
    background-color: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .actionsContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      .actions {
        display: flex;
        align-items: center;
        gap: 16px;
        .bulkActions {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-left: auto;
        }
      }
    }
    .tableContainer {
      margin-top: 16px;
      overflow-x: auto;
      display: flex;
      flex: 1;
      table {
        border-top: 2px solid var(--table-border-color);
        width: 100%;
        // // border-collapse: separate;
        // border-spacing: 15px;
        thead {
          tr {
            th {
              color: var(--label-color);
              font-size: 0.813rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              white-space: nowrap;
              padding: 0 24px;
              div {
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        tbody {
          tr:first-child {
            td {
              border-top: 1px solid var(--table-border-color);
            }
          }
          tr {
            td {
              margin-right: 32px;
              border-bottom: 1px solid var(--table-border-color);
              color: var(--label-color);
              font-size: 0.813rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              white-space: nowrap;
              .directAction {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                .btn {
                  background: transparent;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tableSelectText {
  color: var(--label-color);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.mat-elevation-z8 {
  box-shadow: 0px 1px 2px #00000040 !important;
}

.mat-sort-header-container {
  justify-content: center;
}

.mat-sort-header-container{
  justify-content: start !important;
}

.mdc-data-table__header-cell,
.mdc-data-table__cell ,
.mat-sort-header-content
{
  padding: 0 8px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: start !important;
}

.mdc-data-table__cell {
  padding-top: 5px !important;
}
