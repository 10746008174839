.swal2-popup {
  min-width: 584px;
  // height: 174px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  background: #fff;
  box-shadow:
    0px 20px 20px 0px rgba(0, 0, 0, 0.08),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  .swal2-icon {
    margin-top: 17px;
    scale: 0.7;
  }
  &:has(.swal2-success) {
    h2 {
      color: var(--primary-color);
    }
  }
  h2 {
    padding: 0 1rem;
    color: var(--primary-color);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .swal2-actions {
    .swal2-confirm {
      background-color: var(--primary-color) !important;
    }
    .swal2-deny {
      background-color: var(--grey-color) !important;
    }
  }
}

.notificationTrimmed {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 313px;
  display: block;
  overflow: hidden;
}
