.cdk-global-overlay-wrapper {
  .cdk-overlay-pane {
    // width: 51% !important;
    &:has(.mdc-snackbar) {
      width: 100% !important;
      align-items: flex-end;
    }
  }
}

.mainDialogContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg-color);
  header {
    background: #fff;
    height: 100px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // z-index: 1;
    // box-shadow: 0px 1px 10px #0000001a;
    h2 {
      color: var(--breadcrumb-link-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      text-transform: capitalize;
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .formContainer {
    padding: 32px;
    height: calc(100% - 100px);
    overflow: auto;
    .form {
      border-radius: 4px;
      width: 100%;
      // overflow-y: auto;
      background-color: #fff;
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: min-content;
      gap: 16px;
      &:has(app-validation-error):has(.validation-message) {
        row-gap: 50px;
      }
      .inputContainer {
        flex: 1;
      }
    }
  }
}

app-permissions-details {
  height: 100%;
}
.slide-in-from-right {
  .mdc-dialog__surface {
    border-radius: 0 !important;
  }
  animation: slideInFromRight 0.4s ease forwards;
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
