input {
  //min-height: 36px;
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  &:not([type="checkbox"]) {
    width: 100%;
  }
  &[type="checkbox"] {
    position: relative;
    width: 16px;
    height: 16px;
    min-height: 16px;
    border: 1px solid var(--input-border-color);
    &:checked {
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../../icons/State=on.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  &[search] {
    width: 227px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    padding-left: 40px;
    border-radius: 52px;
    border: 1px solid var(--input-border-color);
    background: #fff;
    position: relative;
  }
  &[type="date"] {
    &[disabled] {
      &::after {
        display: none;
      }
    }
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: 19px;
      width: 19px;
      left: 129px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0 4px 4px 0;
      z-index: 1;
      background-image: url("../../icons/calendar.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: white;
      pointer-events: none;
    }
  }
  &.ng-invalid.ng-touched:not(.mat-date-range-input-inner) {
    border: 1px solid var(--danger-color);
  }
  &:disabled {
    background-color: rgba(215, 215, 215, 0.5);
    cursor: pointer;
    opacity: 0.7;
    pointer-events: none;
    border-color: rgba(215, 215, 215, 0.5);
  }
}

/* For Webkit browsers (Chrome, Safari) */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100px;
  opacity: 0;
  cursor: pointer;
}

.fileFile {
  width: 100%;
  height: 36px;
  min-height: 36px;
  position: relative;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  input[type="file"] {
    display: none;
  }
  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--input-border-color);
    position: absolute;
    width: 100px;
    background-color: var(--table-border-color);
    color: var(--breadcrumb-link-color);
    border-radius: 4px 0 0 4px;
    left: 0;
    top: 0;
  }
}

.topBarActions {
  ng-select {
    width: 233px;
    background-color: var(--grey-active-company-color);
    border-radius: 30px !important;
    .ng-select-container {
      border-radius: 30px !important;
      border: none;
      width: 100%;
      height: 100%;
      background-color: var(--grey-active-company-color);
      .ng-value-container {
        .ng-input {
        }
      }
      .ng-arrow-wrapper {
        display: none;
      }
    }
    ng-dropdown-panel {
      .ng-dropdown-panel-items {
        div {
          .ng-option-disabled {
            background-color: lightgray;
          }
        }
      }
    }
  }
}

ng-select {
  ng-dropdown-panel {
    .ng-dropdown-panel-items {
      div {
        .ng-option-disabled {
          span {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.mat-mdc-menu-panel {
  max-width: unset !important;
  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      border-radius: 0;
      background: transparent;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      span {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

textarea {
  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  width: 100%;
  min-width: 100%;
  height: 36px;
  min-height: 36px;
}

.switchToggle {
  width: 37px;
  height: 24px;
  border-radius: 32px;
  background-color: var(--grey-color);
  position: relative;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &::after {
    transition: all 0.5s ease-in-out;
    content: "";
    width: 20px;
    height: 20px;
    background-color: var(--table-border-color);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
  }
  &:has(input:checked) {
    background-color: var(--primary-color);
    &::after {
      transition: all 0.5s ease-in-out;
      background-color: var(--table-border-color);
      left: unset;
      right: 2px;
    }
  }
  &:has(input[disabled]) {
    // background-color: var(--disabled-primary-color);
    cursor: default;
  }
  input {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

// Assuming your component's selector is 'app-select-employees-form'

.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mdc-checkbox__native-control,
.mdc-checkbox__ripple {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

app-signal-based-paginated-dropdown {
  width: 100%;
}
