@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body:has(router-outlet.ar) {
  font-family: "Noto Sans Arabic", sans-serif;
}
body:has(router-outlet.en) {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

label,
.ng-star-inserted {
  color: var(--label-color);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

p {
  color: var(--paragraph-color);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.sideHeader {
  color: var(--primary-color);
  font-size: 1.01rem;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 16px;
  .number {
    width: 38px;
    height: 23px;
    border-radius: 100px;
    background: var(--list-label-number-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.greyText {
  color: var(--grey-color);
  font-size: 0.813rem;
  font-weight: 400;
  line-height: normal;
}
.danger {
  color: var(--danger-color);
}
.ng-placeholder {
  color: var(--place-holder-color);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

[BorderHighLight="success"] {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background: #dbf0ea;
  border-radius: 49px;
  text-align: center;
  padding: 0.3rem 1rem;
  max-width: fit-content;
}

[BorderHighLight="warning"] {
  color: var(--warning-font-color);
  border: 1px solid var(--warning-border) 2;
  background: #f0e5d9;
  border-radius: 49px;
  padding: 0.3rem 1rem;
  max-width: fit-content;
  text-align: center;
  text-align: center;
}

[BorderHighLight="danger"] {
  color: #fff;
  border: 1px solid var(--danger-color);
  border-radius: 49px;
  text-align: center;
  background: rgba(203, 57, 59, 0.7);
  padding: 0.3rem 1rem;
  max-width: fit-content;
}

[BorderHighLight="basic"] {
  color: var(--grey-color);
  border: 1px solid #ccc;
  border-radius: 49px;
  padding: 0.3rem 1rem;
  max-width: fit-content;
  text-align: center;
  background: rgba(217, 217, 217, 0.15);
}

.statusSuccess {
  background: rgba(198, 248, 198, 0.968) !important;
}

.statusWarning {
  background: #f3d2af !important;
}

.statusDanger {
  background: rgba(239, 137, 138, 0.5) !important;
}
