.policyFormContainer {
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  gap: 16px;
  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: flex-end;
    button {
      min-width: 90px;
    }
  }
  .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.dayAndPerMonthContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .headers {
    display: flex;
    align-items: center;
    gap: 24px;
    label {
      min-width: 203px;
    }
  }
  .rows {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 16px 0;
    .inputContainer {
      min-width: 203px;
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;
      button {
        padding: 5px 10px !important;
        mat-icon {
          font-size: 13px !important;
        }
      }
    }
  }
}
