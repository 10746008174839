app-list-filter {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  .filter {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    flex: 1;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    .filterInputContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      label {
        margin-bottom: 8px;
      }
      ng-select {
        width: 100%;
      }
    }
  }
}
