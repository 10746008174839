mat-sidenav {
  background-color: var(--primary-color) !important;
  width: 250px !important;
  .mat-drawer-inner-container {
    &::-webkit-scrollbar {
      display: none;
    }
    mat-nav-list {
      padding: 32px 24px !important;
      mat-list-item {
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 0.813rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        &.link {
          h4 {
            font-size: 0.813rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
        .mdc-list-item__content {
          .mat-mdc-list-item-unscoped-content {
            color: #fff !important;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
      mat-expansion-panel {
        background-color: transparent;
        box-shadow: none !important;
        mat-expansion-panel-header {
          padding: 0;
          font-size: 0.813rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          padding-right: 4px;
          span {
            mat-icon,
            h4,
            mat-panel-title {
              color: #fff !important;
            }
            align-items: center;
            gap: 8px;

            &::after {
              color: #fff;
            }
          }
        }
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            padding: 0;
            mat-nav-list {
              padding: 0 !important;
              mat-list-item {
                padding-left: 8px;
              }
              mat-expansion-panel {
                padding-left: 8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }
  }
}
mat-list-item {
  &.active-link {
    mat-icon,
    h4 {
      color: var(--active-link-color);
    }
    background-color: #eee;
  }
}
.subTitle {
  color: var(--side-menu-subtitle-color);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
